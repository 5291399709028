<template>
  <div v-if="logs.length" class="space-y-2">
    <h4 class="text-base leading-6 font-medium text-gray-900">
      Log Info
    </h4>
    <asom-client-table
      :columns="[ 'no', 'createdDate', 'message', 'details' ]"
      :data="logs"
      :filterable="false"
    >
      <template v-slot:header_message>Info</template>
      <template v-slot:no="slotScoped">
        {{slotScoped.rowIndex + 1}}
      </template>
      <template v-slot:createdDate="slotScoped">
        {{displayUtcDateTime(slotScoped.data)}}
      </template>
    </asom-client-table>
  </div>
</template>
<script>
import { getListOfStationFaultLog } from "@/services/faultManagement.service";
import { displayUtcDateTime  } from "../../../helpers/dateTimeHelpers";
import get from 'lodash.get'

export default {
  props: {
    stationFaultId: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      logs: [],
      isLoading: false,
      error: null
    }
  },
  mounted() {
    this.loadLogList()
  },
  methods: {
    displayUtcDateTime,
    async loadLogList() {
      this.isLoading = true;
      const resp = await getListOfStationFaultLog({ stationFaultId: this.stationFaultId })
      if (resp.success) {
        this.error = null;
        this.logs = get(resp.payload, "list", []);
      } else {
        this.error = resp.payload
      }
      this.isLoading = false;
    }
  }
}
</script>